import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PageHelmet from 'src/components/PageHelmet'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import StoriesList from 'src/components/StoriesList'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

const prepareStories = (stories) => stories.filter(({ node }) => node.slug !== '_')

class Stories extends Component {
  render() {
    const {
      data: {
        allDatoCmsStory: { edges },
      },
      locale,
      changeLocale,
      intl,
    } = this.props

    const stories = prepareStories(edges)
    return (
      <div>
        <PageHelmet title={intl.formatMessage({ id: 'home.stories' })} locale={locale} defer={false} />
        <Header full={true} locale={locale} changeLocale={changeLocale} />
        <StoriesList stories={stories} locale={locale} />
        <Footer locale={locale} />
      </div>
    )
  }
}

export const query = graphql`
  query Stories($locale: String!) {
    allDatoCmsStory(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          title
          description
          publishDate(formatString: "DD.MM")
          slug
          image {
            id
            alt
            url
            fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress,format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          darkSubstrateForBackground
          storyCards {
            title
            body
            backgroundImage {
              id
              url
              alt
            }
          }
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'storiesList',
}

export default withLayout(customProps)(injectIntl(Stories))
