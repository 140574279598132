import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Item from 'src/components/StoriesFeed/Item'
import styles from './StoriesList.module.scss'
import MissingContent from 'src/components/MissingContent'
import { injectIntl } from 'react-intl'

class StoriesList extends Component {
  render() {
    const { className, stories, style, locale, intl } = this.props

    return (
      <>
        <section id="stories" className={classNames(className, styles.root)} style={style}>
          <div className={styles.inner}>
            <div className={styles.wrap}>
              {stories.length > 0 ? (
                stories.map(
                  (item) =>
                    item.node.title && (
                      <Item className={styles.marginBottom} key={item.node.slug} locale={locale} {...item} />
                    ),
                )
              ) : (
                <MissingContent locale={locale} title={intl.formatMessage({ id: 'home.missingNews' })} />
              )}
            </div>
          </div>
        </section>
      </>
    )
  }
}

StoriesList.propTypes = {
  className: PropTypes.string,
  stories: PropTypes.array,
  style: PropTypes.string,
  locale: PropTypes.string.isRequired,
}

export default injectIntl(StoriesList)
