import React, { Component } from 'react'
import { Link } from 'gatsby'
import SlidesCount from 'src/components/svg/SlidesCount'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Img from 'gatsby-image'
import styles from './Item.module.scss'
import { getLink } from 'src/utils'

class Item extends Component {
  render() {
    const {
      className,
      node: { slug, publishDate, title, storyCards, image, darkSubstrateForBackground },
      locale,
    } = this.props

    return (
      <article className={classNames(className, styles.root)}>
        <Link to={getLink(locale, `story/${slug}`)}>
          <Img
            fluid={image.fluid}
            className={classNames(styles.cover, darkSubstrateForBackground && styles.darkSubstrateForBackground)}
          />
          <div className={styles.date}>{publishDate}</div>
          <div className={styles.slides}>
            <SlidesCount />
            <span className={styles.slidesCount}>{storyCards.length}</span>
          </div>
          <h3 className={styles.title}>{title && title.length >= 100 ? `${title.substring(0, 100)}...` : title}</h3>
        </Link>
      </article>
    )
  }
}

Item.displayName = 'StoriesFeed.Item'

Item.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
  publishdate: PropTypes.string,
  title: PropTypes.string,
}

export default Item
