import React from 'react'

const SlidesCount = (props) => (
  <svg width={20} height={22} fill="none" {...props}>
    <path
      d="M0 1.991C0 .891.9 0 1.991 0h12.004C15.102 0 16 .89 16 1.991V20.01c0 1.1-.895 1.991-1.994 1.991H1.994A1.991 1.991 0 0 1 0 20.009V1.99zM17 4h1.5c.828 0 1.5.675 1.5 1.498v11.004c0 .827-.666 1.498-1.5 1.498H17V4z"
      fill="#fff"
    />
  </svg>
)

export default SlidesCount
